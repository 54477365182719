@use 'sass:color';
@import 'OK/styles/util';

.authorContainer {
  align-items: center;
  display: flex;
  height: $lineHeightMd * 2;
  justify-content: space-between;
}

.authorImage {
  border-radius: 30px;
  height: 60px;
}

.authorName {
  margin-bottom: 0;
}

.authorOrganisationName {
  margin-bottom: 0;
}

.authorJobTitle {
  margin-bottom: 0;
}

.backgroundImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: $cardBorderRadius;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1);
  transition: transform $imageZoonOnHoverTiming;
}

.badge {
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  color: $textColorLight;
  font-weight: bold;
  left: 10px;
  padding: 4px $contentMarginSm;
  position: absolute;
  top: -2px;
  z-index: 2;
}

.container {
  cursor: pointer;
  padding-top: 2px;
  position: relative;

  &:hover {
    .backgroundImage {
      transform: $imageZoomOnHoverScale;
    }
  }
}

.containerInner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.externalLinkIcon {
  vertical-align: inherit;
}

.media {
  border-radius: $cardBorderRadius;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.mediaOverlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: scale(1);
  width: 100%;
}

.mediaSizer {
  padding-top: 100%;
  height: 0;
  width: 100%;
}

.readButton {
  margin: $contentMarginMd 0 0 0;
}

.title {
  font-size: $fontSizeSectionHeader;
  font-weight: bold;
  line-height: $lineHeightSectionHeader;
  height: $lineHeightSectionHeader * 2;
  margin-bottom: $contentMarginSm;
}

.topLayer {
  display: flex;
  flex-direction: column;
  padding: $contentMarginMd $contentMarginMd $contentMarginLg $contentMarginMd;
  transform: scale(1);
}

@include forLightTheme() {
  .badge {
    background-color: $navigationColorCard;
  }

  .mediaOverlay {
    background: linear-gradient(
      to top,
      color.change($brandColorAlternate, $alpha: 0.9) 0%,
      color.change($brandColorAlternate, $alpha: 0.9) 173px,
      transparent 100%
    );
  }

  .title {
    color: $navigationColorCard;
  }
}

@include forDarkTheme() {
  .badge {
    background-color: $navigationColorCardDark;
  }

  .mediaOverlay {
    background: linear-gradient(
      to top,
      color.change($brandColor, $alpha: 0.9) 0%,
      color.change($brandColor, $alpha: 0.9) 173px,
      transparent 100%
    );
  }

  .title {
    color: $navigationColorCardDark;
  }
}

// doiuse-disable calc
// doiuse-disable css-masks

@import 'OK/styles/util';

.featuresCarousel {
  margin: 0 (-$contentMarginLg) !important;
  padding-top: 2px;

  :global(.os-scrollbar-horizontal) {
    margin-top: $contentMarginSm;
    padding-left: $contentMarginLg !important;
    padding-right: $contentMarginLg !important;
  }

  mask-image: linear-gradient(
    to right,
    transparent 0%,
    transparent 5px,
    #000 10px,
    #000 calc(100% - 10px),
    transparent calc(100% - 5px),
    transparent 100%
  ) !important;
}

.featuresCarouselInner {
  padding: 2px 0 0 0 !important;
}

.featureSlide {
  margin: 0 0 0 $contentMarginSm;

  &:last-child {
    border-right-width: $contentMarginSm !important;
  }
}

.floatingCloseButton {
  margin: 0 !important;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: $contentMarginMd;

  > * {
    margin-bottom: 0;
  }
}

.nextSectionButton {
  bottom: 0 !important;
  position: absolute;
  right: $contentMarginXxl;

  @include forMinScreenWidth(medium) {
    right: $contentMarginLg;
  }
}

.popupButtonsContainer {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: 614px;
  }
}

.popupContent {
  position: relative;
}

.swipeInstructions {
  margin: $contentMarginMd 0 0 0;
}

@use 'sass:color';
@import 'OK/styles/util';

.badge {
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  color: $textColorLight;
  font-weight: bold;
  left: 10px;
  padding: 4px $contentMarginSm;
  position: absolute;
  top: -2px;
  z-index: 2;
}

.container {
  background: none;
  overflow: hidden;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  &:hover {
    .media {
      transform: $imageZoomOnHoverScale;
    }
  }
}

.containerInner {
}

.date {
  margin-bottom: 0;
}

.detailsContainer {
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius !important;
  padding: $contentMarginMd $contentMarginMd $contentMarginLg $contentMarginMd;
}

.eventType {
  margin: 0;
}

.media {
  backface-visibility: hidden;
  transform: scale(1);
  transition: transform $imageZoonOnHoverTiming;
}

.mediaContainer {
  border-radius: $cardBorderRadius $cardBorderRadius 0 0;
  flex: 1 1 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.square {
    height: 0;
    padding-top: 100%;
  }
}

.mediaOverlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.title {
  font-size: $fontSizeSectionHeader;
  font-weight: bold;
  line-height: $lineHeightSectionHeader;
  height: $lineHeightSectionHeader * 2;
  margin-bottom: $contentMarginSm;
}

@include forLightTheme() {
  .badge {
    background-color: $navigationColorCard;
  }

  .mediaOverlay {
    background-color: color.change($brandColorAlternate, $alpha: 0.4);
  }

  .title {
    color: $navigationColorCard;
  }
}

@include forDarkTheme() {
  .badge {
    background-color: $navigationColorCardDark;
  }

  .mediaOverlay {
    background-color: color.change($brandColor, $alpha: 0.4);
  }

  .title {
    color: $navigationColorCardDark;
  }
}

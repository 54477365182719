$animationDuration: 600ms;
$easing: cubic-bezier(0.76, 0, 0.24, 1);

.container {
  position: relative;
  overflow: hidden;
}

.letter {
  display: inline-block;
  transition: transform $animationDuration $easing;
}

.placeholderWord {
  display: inline-block;
}

.word {
  opacity: 0;
  position: absolute;
  top: 100%;
  transition: opacity 0ms;
  transition-delay: $animationDuration;
  white-space: nowrap;

  &.previous {
    .letter {
      transform: translateY(-200%);
    }
  }

  &.current {
    opacity: 1;
    transition-delay: 0ms;

    .letter {
      transform: translateY(-100%);
    }
  }

  &.next {
    .letter {
      transform: translateY(0);
    }
  }
}

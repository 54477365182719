@use 'sass:color';
@import 'OK/styles/util';

.background {
  background-position: center;
  background-size: cover;
  left: 0;
  position: absolute;
  transform: skewY(3deg);
  width: 100%;
}

.container {
  position: relative;
}

.content {
  position: relative;
}

.skewedMask {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: skewY(-3deg);
  width: 100%;
}

.waveBack,
.waveFront {
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-play-state: paused;
  animation-timing-function: ease-in-out;
  height: calc(100% + 30px);
  left: 0;
  opacity: 50%;
  position: absolute;
  right: 0;
}

.waveBack {
  animation-delay: 2s;
  animation-name: waveBackAnimation;
  bottom: 0;
  transform: skewY(-4deg) translateY(15px);
}

.waveFront {
  animation-name: waveFrontAnimation;
  top: 0;
  transform: skewY(-2.5deg) translateY(-10px);
}

@keyframes waveBackAnimation {
  0% {
    transform: skewY(-4deg) translateY(15px);
  }

  25% {
    transform: skewY(-4deg) translateY(0);
  }

  60% {
    transform: skewY(-4deg) translateY(30px);
  }

  100% {
    transform: skewY(-4deg) translateY(15px);
  }
}

@keyframes waveFrontAnimation {
  0% {
    transform: skewY(-2.5deg) translateY(-10px);
  }

  20% {
    transform: skewY(-2.5deg) translateY(-30px);
  }

  55% {
    transform: skewY(-2.5deg) translateY(-5px);
  }

  80% {
    transform: skewY(-2.5deg) translateY(-10px);
  }
}

@include forLightTheme() {
  .background {
    background-color: $contentBackgroundColor;
  }

  .waveBack,
  .waveFront {
    background-color: $brandColor;
  }
}

@include forDarkTheme() {
  .background {
    background-color: $contentBackgroundColorDark;
  }

  .waveBack,
  .waveFront {
    background-color: $midtonesColorStaticDark;
  }
}

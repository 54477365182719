@use 'sass:map';

@import './maps';

@mixin fontDefault() {
  font-family: 'Noto Sans', Arial, sans-serif;
}

@mixin fontMono() {
  font-family: 'Noto Sans Mono', monospace;
}

/*
  Generate media query targeting @2x screen resolutions.
*/
@mixin for2xResolution() {
  // doiuse-disable css-media-resolution
  @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

// Include CSS for dark theme only
@mixin forDarkTheme() {
  :global(.theme_dark) {
    @content;
  }
}

// Include CSS for light theme only
@mixin forLightTheme() {
  :global(.theme_light) {
    @content;
  }
}

/*
  Generate media query for a minimum screen width.

  If the $width provided matches an entry in the $breakpoints map, that value will be used. Otherwise, $width will be
  used as-is and should be a valid CSS size value.
*/
@mixin forMinScreenWidth($width) {
  $breakpoint: $width;
  @if map.has-key($breakpoints, $width) {
    $breakpoint: map.get($breakpoints, $width);
  }

  @media (min-width: $breakpoint) {
    @content;
  }
}

/*
  Generate media query for a minimum screen width.

  If the $width provided matches an entry in the $breakpoints map, that value will be used. Otherwise, $width will be
  used as-is and should be a valid CSS size value.
*/
@mixin forMaxScreenWidth($width) {
  $breakpoint: $width;
  @if map.has-key($breakpoints, $width) {
    $breakpoint: map.get($breakpoints, $width);
  }
  $breakpoint: $breakpoint - 1px; // Apply CSS rules up to (but not including) breakpoint width.

  @media (max-width: $breakpoint) {
    @content;
  }
}

// Increase clickable area to 44px x 44px
@mixin increaseClickableArea($verticalDistance, $horizontalDistance: $verticalDistance, $position: relative) {
  position: $position;

  &::before {
    bottom: -$verticalDistance;
    content: '';
    left: -$horizontalDistance;
    position: absolute;
    right: -$horizontalDistance;
    top: -$verticalDistance;
  }
}

@mixin overlayScrollbarsDefaultScrollbarPadding() {
  :global(.os-scrollbar-horizontal) {
    padding-left: $contentMarginLg;
    padding-right: $contentMarginLg;
  }

  @include forMinScreenWidth(medium) {
    :global(.os-scrollbar-horizontal) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // :global(.os-viewport) {
  //   padding-bottom: $contentMarginLg !important;
  // }
}

@mixin propertyWithFallbackValue($prop, $value, $fallbackValue) {
  #{$prop}: $fallbackValue;
  #{$prop}: $value;
}

/* Pure React Carousel customizations */
@mixin reactCarouselCustomizations() {
  :global {
    .carousel__dot-group {
      button {
        @include increaseClickableArea(14px);
        border-radius: 8px;
        border-style: solid;
        height: 16px;
        margin: 0 16px;
        padding: 0;
        width: 16px;

        &:not(:disabled) {
          background: none;
        }
      }
    }

    .carousel__slide-focus-ring {
      display: none !important;
    }
  }
}

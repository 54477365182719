@use 'sass:color';
@import 'OK/styles/util';

.badge {
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  position: absolute;
  font-weight: bold;
  top: 0;
  left: 10px;
  padding: 4px 10px;
  z-index: 2;
}

.backgroundImage {
  border-radius: $cardBorderRadius;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scale(1);
  transition: transform $imageZoonOnHoverTiming;
}

.container {
  cursor: pointer;
  min-height: 415px;
  position: relative;

  &:hover {
    .backgroundImage {
      transform: $imageZoomOnHoverScale;
    }
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.media {
  border-radius: $cardBorderRadius;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.mediaOverlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.mediaSizer {
  padding-top: 100%;
  height: 0;
  width: 100%;
}

.title {
  margin-bottom: 15px;
}

.topLayer {
  z-index: 1;
}

@include forLightTheme() {
  .badge {
    background-color: $navigationColor;
    color: $textColorLight;
  }

  .mediaOverlay {
    background: linear-gradient(
      to top,
      color.change($brandColorAlternate, $alpha: 0.9) 0%,
      color.change($brandColorAlternate, $alpha: 0.9) 173px,
      transparent 100%
    );
  }

  .title {
    color: $navigationColor;
  }
}

@include forDarkTheme() {
  .badge {
    background-color: $navigationColor;
    color: $textColorLight;
  }

  .mediaOverlay {
    background: linear-gradient(
      to top,
      color.change($brandColor, $alpha: 0.9) 0%,
      color.change($brandColor, $alpha: 0.9) 173px,
      transparent 100%
    );
  }

  .title {
    color: $navigationColor;
  }
}

@import 'OK/styles/util';

.container {
  position: relative;
  width: 210px;
}

.description {
  margin: 0;
}

.infoContainer {
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
}

.mediaContainer {
  border-radius: $cardBorderRadius $cardBorderRadius 0 0;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
}

.media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.paidTag {
  border-radius: 0 0 $cardBorderRadius $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  font-weight: bold;
  left: 20px;
  padding: 4px 10px;
  position: absolute;
  top: -2px;
  z-index: 1;
}

.title {
  margin-bottom: $contentMarginSm;
}

@include forLightTheme() {
  .paidTag {
    background-color: $contentBackgroundColor;
    border-color: $midtonesColorStatic;
  }
}

@include forDarkTheme() {
  .paidTag {
    background-color: $contentBackgroundColorDark;
    border-color: $midtonesColorStaticDark;
  }
}

@import 'OK/styles/util';

// doiuse-disable css-image-set

$sectionButtonMaxWidth: 275px;
$sectionIntroMaxWidth: 440px;
$skewedSectionPaddingBottomDesktop: 30px;
$skewedSectionPaddingBottomMobile: 30px;
$skewedSectionPaddingTop: 30px;

.annualPricingToggleContainer {
  display: flex;
  margin: $contentMarginLg $contentMarginLg 0 0;
}

.annualPricingToggleSection {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @include forMinScreenWidth(medium) {
    justify-content: flex-end;
  }
}

.annualPricingLabel {
  margin: 0 $contentMarginMd 0 0;
}

.appPreviewOuterContainer {
  @include forMinScreenWidth(medium) {
    position: relative;
  }
}

.appPreviewContainer {
  height: 565px;
  left: calc(((100% - 250px) / 2) - 20px - 30px);
  margin: 0 auto $contentMarginXxl;
  position: relative;
  top: -50px;

  @include forMinScreenWidth(medium) {
    height: auto;
    left: 470px;
    position: absolute;
    top: -598px;
  }
}

.calculatorContactUsButton {
  margin-bottom: $contentMarginXs;
  margin-top: 25px;

  @include forMinScreenWidth(medium) {
    width: $sectionButtonMaxWidth;
  }
}

.container {
  overflow: hidden;
}

.circularSupplyChainGraphic {
  display: block;
  margin: $contentMarginXxl auto $contentMarginXl;
  max-width: 100%;

  @include forMinScreenWidth(medium) {
    float: right;
    margin: 0;
  }
}

.demoEmailInput {
  margin-bottom: $contentMarginMd;
}

.easyItem {
  flex: 1 1 0;
  position: relative;

  @include forMinScreenWidth(medium) {
    margin: 0 $contentMarginMd 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .easyItemContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .easyItemHover {
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }

    .easyItemImg {
      border-radius: 5px;
      font-size: 0;
      line-height: 0;
      margin-bottom: $contentMarginMd;
      overflow: hidden;
      width: 100%;

      img {
        transition: transform 0.5s ease;
        width: 100%;
      }
    }

    .easyItemLink {
      margin-bottom: $contentMarginLg;

      @include forMinScreenWidth(medium) {
        margin-bottom: 0;
      }
    }
  }
}

.easyItemHeader {
  margin-bottom: $contentMarginSm;
}

.easySection {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include forMinScreenWidth(medium) {
    align-items: initial;
    flex-direction: row;
  }
}

.easySectionIntro {
  margin: 0 auto;
  padding: 0 $contentMarginMd;

  @include forMinScreenWidth(medium) {
    margin: 0;
  }
}

.emailInputHeader,
.licensesSectionHeader,
.potentialEarningsHeader {
  margin-bottom: $contentMarginSm;
}

.featuresSection {
  margin-top: -30px;
  padding-bottom: $skewedSectionPaddingBottomMobile;
  padding-top: $skewedSectionPaddingTop;

  @include forMinScreenWidth(medium) {
    margin-top: -80px;
    padding-bottom: $skewedSectionPaddingBottomDesktop;
  }
}

.footnote {
  max-width: 550px;
}

.formDescription,
.formDescription a {
  margin-bottom: 0;
}

.fullFeaturesLink,
.gradesLink,
.tagsLink {
  margin-bottom: 0;
}

.goodChoicesSection {
  margin-bottom: $contentMarginSm;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
  }
}

.heroButton {
  &:nth-child(2) {
    margin-bottom: $contentMarginSm;
  }

  &:nth-child(3) {
    margin-bottom: $contentMarginLg;
  }

  @include forMinScreenWidth(medium) {
    width: 275px;
  }
}

.heroButtonAuth {
  &:nth-child(2) {
    margin-bottom: $contentMarginLg;
  }
}

.heroDescription {
  margin-bottom: 0;
  width: 275px;

  @include forMinScreenWidth(medium) {
    font-size: $fontSizeSectionHeader;
    line-height: $lineHeightSectionHeader;
    width: 400px;
  }
}

.heroSection {
  padding: ($mainMenuHeight + $contentMarginLg) 0 80px; // Padding top is height of main menu + 30
  position: relative;

  @include forMinScreenWidth(medium) {
    padding: ($mainMenuHeight + $contentMarginLg) 0 130px;
  }

  .heroBackground {
    background-image: url('/img/hero_background.jpg');
    background-position: -40px center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    @include for2xResolution() {
      background-image: url('/img/hero_background@2x.jpg');
    }

    @include forMinScreenWidth(medium) {
      background-position: center;
    }
  }
}

.industryLeadersSection {
  margin-top: 50px;
}

.inputMessage {
  margin: -15px 0 $contentMarginSm;
}

.landingContainer {
  color: $textColorLight;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.licensesSection {
  @include forMinScreenWidth(medium) {
    width: calc(100% - 120px);
  }
}

.licensesSlider,
.profitSlider,
.turnoverSlider {
  margin-bottom: 60px;
  width: 100%;
}

.licensesSlider {
  margin-bottom: 40px;
  width: calc(100% - 120px) !important;

  @include forMinScreenWidth(medium) {
    width: 100% !important;
  }
}

.link {
  max-width: 235px;

  @include forMinScreenWidth(medium) {
    max-width: 207px;
  }
}

.mediaLogos {
  display: block;
  margin-bottom: $contentMarginLg;
  margin-top: $contentMarginLg;
  width: 100%;
}

.inlineMessagesButton {
  height: $lineHeightMd;
  vertical-align: top;
}

.pageMenuTab {
  padding: $contentMarginSm $contentMarginXs !important;
}

.potentialEarningsCurrency {
  display: inline-block;
  margin-left: $contentMarginSm;

  p {
    margin: 0;
  }
}

.potentialEarningsValue {
  display: inline-block;
  font-size: $fontSizeFocal;
  line-height: $lineHeightFocal;
  margin: 0;
}

.pricingCard {
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
}

.pricingCarouselContainer {
  margin-bottom: $contentMarginSm;

  @include forMinScreenWidth(medium) {
    margin-bottom: $contentMarginLg;
  }
}

.pricingConversationButton {
  margin-bottom: $contentMarginXl;

  @include forMinScreenWidth(medium) {
    max-width: $sectionButtonMaxWidth;
  }
}

.pricingSectionContent {
  padding-bottom: 0;
  position: relative;

  .sectionHeader {
    margin-bottom: 20px;

    @include forMinScreenWidth(medium) {
      margin-bottom: 30px;
    }
  }
}

.pricingSection {
  padding-top: 100px;

  .pancakes {
    display: none;
    left: 200px;
    position: absolute;
    width: 157px;

    @include forMinScreenWidth(medium) {
      display: block;
      left: auto;
      right: $contentMarginLg;
      top: -60px;
      width: auto;
    }

    img {
      width: 100%;
    }
  }

  .sectionIntro {
    margin-bottom: 0;
  }
}

.pricingSlide {
  flex: 0 0 $cardWidthMobile;

  @include forMinScreenWidth(medium) {
    flex-grow: 1;
    flex-shrink: 1 !important;
    max-width: none;
    min-width: 0;
  }
}

.profitCalculator {
  order: 1;
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    order: 2;
  }
}

.profitExplanation {
  margin-top: $contentMarginMd;
  order: 2;

  @include forMinScreenWidth(medium) {
    margin-top: 0;
    order: 1;
  }
}

.sectionButton {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: $sectionButtonMaxWidth;
  }
}

.sectionButtonAuth {
  margin-bottom: $contentMarginMd !important;
}

.sectionHeader {
  margin-bottom: $contentMarginLg;
}

.sectionLink {
  width: 206px;
}

.scrollToTopButton {
  align-items: center;
  display: flex;
  font-weight: bold;
  height: 100%;
  margin: 0;
  padding: 0;
}

.scrollToTopIcon {
  margin-right: $contentMarginSm;
  position: relative;
  top: 3px;
  transform: rotate(-90deg);
}

.sectionIntro {
  margin-bottom: $contentMarginLg;
  max-width: $sectionIntroMaxWidth;

  h2 + p {
    margin-top: 21px;
  }

  h3 {
    margin-bottom: $contentMarginLg;
  }

  h5 {
    margin-bottom: $contentMarginSm;
  }

  p {
    margin-bottom: $contentMarginSm;
  }
}

.sectionSubHeader {
  margin-bottom: $contentMarginSm;
}

.splitLayout {
  display: flex;
  flex-direction: column;

  @include forMinScreenWidth(medium) {
    flex-direction: row;

    .column {
      flex: 1 1 50%;

      &:first-child {
        margin-right: 60px;
      }
    }
  }
}

.stat {
  flex: 1 1 0;

  @include forMinScreenWidth(medium) {
    margin-right: $contentMarginLg;

    h3 {
      margin-bottom: $contentMarginMd;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  h3 {
    margin-bottom: $contentMarginSm;
  }
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: $contentMarginSm;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
  }
}

.tagline {
  font-size: 35.6px;
  line-height: 53.4px;

  @include forMinScreenWidth(medium) {
    font-size: calc-size-for-screen-width-range(
      3rem,
      3.5rem,
      get-breakpoint-width(medium),
      get-breakpoint-width(large),
      true
    );
    line-height: calc-size-for-screen-width-range(
      4.5rem,
      5.25rem,
      get-breakpoint-width(medium),
      get-breakpoint-width(large),
      true
    );
  }

  @include forMinScreenWidth(large) {
    font-size: 3.5rem;
    line-height: 5.25rem;
  }
}

.testimonialCard {
  margin-bottom: $contentMarginMd;
  width: 100%;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
    margin-right: $contentMarginMd;
    flex: 1 1 calc(33.33% - $contentMarginMd);
    width: calc(33.33% - $contentMarginMd);

    &:last-child {
      margin-right: 0;
    }
  }
}

.testimonials {
  display: flex;
  flex-direction: column;
  margin-bottom: $contentMarginXl;

  @include forMinScreenWidth(medium) {
    flex-direction: row;
  }
}

.deskBananas {
  left: -10px;
  margin-bottom: $contentMarginMd;
  max-width: 489px;
  position: relative;
  width: 100%;

  @include forMinScreenWidth(medium) {
    left: -20px;
    margin-bottom: $contentMarginLg;
    margin-left: auto;
  }
}

.eventCard {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include forMinScreenWidth(medium) {
    position: relative !important;
    height: 100%;
  }
}

.eventCardContainer {
  margin-bottom: $contentMarginMd;
  position: relative;

  @include forMinScreenWidth(medium) {
    flex-basis: 33%;
    width: 33%;
    margin: 0 $contentMarginSm;
  }
}

.eventCardLoadingContainer {
  height: 100%;
  left: 0;
  position: absolute !important;
  top: 0;
  width: 100%;

  > div {
    height: 100%;
  }

  @include forMinScreenWidth(medium) {
    position: relative !important;
  }
}

.eventCardSquarify {
  margin-bottom: $contentMarginSm;
}

.eventLink {
  height: $minClickableHeight;
  margin: 0;
}

.eventsContainer {
  @include forMinScreenWidth(medium) {
    display: flex;
    flex-direction: row;
    margin-left: -$contentMarginSm;
  }
}

.takeAnOKStepContainer {
  padding-bottom: $contentMarginHuge;
  padding-top: $contentMarginXxl;
}

.trustworthyContent {
  .trustworthySectionIntroContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: none;
    width: 100%;

    @include forMinScreenWidth(medium) {
      flex-direction: row;
    }
  }

  .trustworthyIntroLeft {
    flex: 4 4;
    margin-bottom: $contentMarginMd;

    @include forMinScreenWidth(medium) {
      margin-bottom: $contentMarginLg;
      max-width: $sectionIntroMaxWidth;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  .trustworthyIntroRight {
    align-items: flex-end;
    display: flex;
    flex: 6 6;

    @include forMinScreenWidth(medium) {
      // max-width: 33%;
    }
  }
}

.trustworthySection {
  padding-bottom: $skewedSectionPaddingBottomMobile;
  padding-top: $skewedSectionPaddingTop;

  @include forMinScreenWidth(medium) {
    padding-bottom: $skewedSectionPaddingBottomDesktop - 20px;
  }
}

.trustworthySectionLightbulb {
  display: none;
  transform: rotateY(180deg);

  @include forMinScreenWidth(medium) {
    display: block;
    left: calc(50% - 64px);
    position: absolute;
    top: 20px;
    z-index: -1;
  }
}

.worthwhileBackground {
  background-position: right !important;
  background-repeat: no-repeat;
  background-size: cover;

  @include forMinScreenWidth(medium) {
    background-position: center !important;
  }
}

.worthwhileContainer {
  margin-top: -50px;
  padding-bottom: 60px;
  padding-top: 150px;
}

.worthwhileFade {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
}

.worthwhileOuterContainer {
  position: relative;
  z-index: -1;
}

.zen {
  bottom: -50px;
  position: absolute;
  right: -10px;

  @include forMinScreenWidth(medium) {
    bottom: -20px;
  }
}

// Light theme

@include forLightTheme() {
  .pricingCard {
    border-color: $contentBackgroundColorCard;

    &:not(.priority) {
      background-color: $contentBackgroundColor;
      border-color: $midtonesColorStatic;
    }
  }

  .profitCalculator {
    background-color: $midtonesColorStatic;
  }

  .scrollToTopButton {
    color: $textColor;
  }

  .trustworthyBackground {
    background-color: $contentBackgroundColorCard !important;
  }

  .worthwhileBackground {
    background-image: url('/img/beneficial_bg.jpg');
    background-image: image-set(url('/img/beneficial_bg.jpg') 1x, url('/img/beneficial_bg@2x.jpg') 2x);
  }

  .worthwhileFade {
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
}

// Dark theme

@include forDarkTheme() {
  .pricingCard {
    border-color: $contentBackgroundColorCardDark;

    &:not(.priority) {
      background-color: $contentBackgroundColorDark;
      border-color: $midtonesColorStaticDark;
    }
  }

  .profitCalculator {
    background-color: $midtonesColorStaticDark;
  }

  .scrollToTopButton {
    color: $textColorLight;
  }

  .trustworthyBackground {
    background-color: $contentBackgroundColorCardDark !important;
  }

  .worthwhileBackground {
    background-image: url('/img/beneficial_bg_dark.jpg');
    background-image: image-set(url('/img/beneficial_bg_dark.jpg') 1x, url('/img/beneficial_bg_dark@2x.jpg') 2x);
  }

  .worthwhileFade {
    background: linear-gradient(rgba(1, 1, 1, 0), rgba(1, 1, 1, 1));
  }
}

.buttonIcon {
  width: 100%;
}

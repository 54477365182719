@import 'OK/styles/util';

.authorContainer {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.authorName {
  margin-bottom: 0;
}

.containerInner {
  display: flex;
  flex-direction: column;
}

.logo {
}

.logoContainer {
  align-items: center;
  height: 60px;
  display: flex;
}

.organisationName {
  margin: 0;
}

.parentheses {
  font-size: 40px;
  font-style: italic;
  font-weight: bold;
  line-height: 60px;
  margin-bottom: 0;
}

.quote {
  margin-bottom: $contentMarginLg;

  :last-child {
    margin-bottom: 0;
  }
}

.separator {
  margin-bottom: $contentMarginXxl;
  margin-top: auto;
}

@import 'OK/styles/util';

.button {
  border-color: $navigationColor !important;
  bottom: $contentMarginLg;
  justify-content: center;
  opacity: 1;
  position: absolute;
  right: $contentMarginLg;
  transition: opacity $timingShort ease;
  width: 40px;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.icon {
  transform: rotate(90deg) translateX(-5px);
}

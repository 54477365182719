@use 'sass:color';
@import 'OK/styles/util';

$desktopButtonWidth: 275px;

.button {
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: $desktopButtonWidth;
  }
}

.error {
  margin-bottom: 15px;
  margin-top: -15px;
}

.header {
  padding: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-between;
}

.input {
  margin-bottom: $contentMarginMd;
  width: 100%;

  @include forMinScreenWidth(medium) {
    width: $desktopButtonWidth;
  }
}

.inputLabel {
  margin-bottom: $contentMarginSm;
}

.pdfPreview {
  margin-bottom: 300px;
  max-width: 100%;

  @include forMinScreenWidth(medium) {
    margin-bottom: 0;
  }
}

.popup {
  max-width: $contentMaxWidthMobile;
  padding: 0;
  position: relative;

  @include forMinScreenWidth(medium) {
    max-width: $contentMaxWidthDesktop;
  }
}

.previewFade {
  min-height: 500px;
  padding-top: 230px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.textContainer {
  padding-left: $contentMarginLg;
  padding-right: $contentMarginLg;
}

@include forLightTheme() {
  .header {
    background: linear-gradient(to bottom, $contentBackgroundColorCard 0%, transparent 100%);
  }

  .previewFade {
    background: linear-gradient(
      to top,
      $contentBackgroundColorCard 0%,
      $contentBackgroundColorCard calc(100% - 200px),
      transparent 100%
    );
  }
}

@include forDarkTheme() {
  .header {
    background: linear-gradient(to bottom, $contentBackgroundColorCardDark 0%, transparent 100%);
  }

  .previewFade {
    background: linear-gradient(
      to top,
      $contentBackgroundColorCardDark 0%,
      $contentBackgroundColorCardDark calc(100% - 200px),
      transparent 100%
    );
  }
}
